<template>
  <div>
    <div class="title">Whiskey</div>
    <div class="spinner" v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
    <div v-if="items.length > 0" class="items">
      <div v-for="item in items" :key="item[0]">
        <item
          :title="item[0]"
          :description="item[1]"
          :price="item[2]"
          :image_url="item[3]"
        ></item>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async mounted() {
    this.isLoading = true;
    const response = await fetch(
      "https://europe-central2-perem-340414.cloudfunctions.net/getPeremEtel?tab=whiskey"
    );
    const items = await response.json();
    this.items = items.items;
    this.isLoading = false;
  },
  data() {
    return {
      items: [],
      isLoading: false,
    };
  },
};
</script>

<style></style>
